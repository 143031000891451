<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Domeinnaam vrij</h1>
        <div>
          <h2>
            <img
              class="framed-image"
              style="float: right; margin-left: 20px;"
              src="../../assets/images/img-domeinen2.jpg"
              height="254"
              width="300"
              title="domeinnaam vrij"
              alt="domeinnaam vrij"
            >
            Is mijn domeinnaam vrij? Direct checken
          </h2>
          <p>Domeinnaam vrij en die registreren? We maken het graag gemakkelijk om dit te checken. Bekijk of het domein vrij is dat u graag wilt vastleggen, gewoon met onze domeincheck. In een paar tellen kunt u naar het domein zoeken en nagaan of dit nog beschikbaar is.</p>
          <p><i>Tip: de domeinnaam niet vrij met de .nl-extensie? Probeer ook .eu, .com en andere extensies die er beschikbaar zijn.</i></p>
          <p>Zodra de URL nog vrij blijkt kunt u die meteen registreren. Vraag de domeinnaam aan, zodat wij er mee aan de slag kunnen. We doen de aanvraag bij de Stichting Internet Domein Registratie (SIDN), waarna we dit zo snel mogelijk voor u in orde maken. Ondertussen kunt u een pakket voor eventuele hosting kiezen, zodat u snel met de nieuwe website, webshop of applicatie aan de slag kunt.</p>
        </div>
        <h3>Domeinnaam vrij? Doe de check</h3>
        <p>Benieuwd of de domeinnaam vrij is? Dat kunt u eenvoudig online checken, daar heeft u ons niet bij nodig. U kunt nagaan of een domein vrij is door die in te vullen in onze checker. Het is voldoende om het domein in te vullen dat u graag zou willen registreren. We gaan op zoek in de database en kunnen aangeven of het nog mogelijk is om deze vast te leggen. De domein vrij check hoeft maximaal een paar minuten te duren, inclusief de aanvraag daarvan indien u daar gebruik van kunt maken.</p>
        <p>U kunt online een domein zoeken om dit vast te leggen. Blijkt het domein bezet? Kies dan voor een andere domeinnaam die nog wel vrij is. Of neem contact op met de huidige eigenaar, zodat u het domein mogelijk kunt overnemen.</p>
        <h3>Bekijk of uw URL nog vrij is</h3>
        <p>Online eenvoudig controleren of een URL nog vrij is: wij maken het mogelijk met onze domeinchecker. Het is voldoende om de URL in te typen, waarna wij aangeven of die vrij is. Blijkt de URL vrij? Dan kunt u die meteen registreren, door aan te geven dat u daar graag gebruik van wilt maken. Wij gaan er voor u mee aan de slag, zodat u de eerste bent die deze kan vastleggen.</p>
        <h3>Domeinnaam aanvragen</h3>
        <p>U kunt op die manier een <router-link to="/domeinnaam-aanvragen/">domeinnaam aanvragen</router-link>. Het domein bestaat uit de URL die u wenst, samen met de extensie die u kiest. Dat kan .nl, .eu of .com zijn, net als een van de minder bekende extensies. Wij zorgen ervoor dat we de aanvraag voor het domein of de URL in orde maken. Het is uiteraard van belang dat het domein of de URL vrij is, zodat we daarvoor de aanvraag kunnen doen. Meer weten of ondersteuning nodig? Neem dan contact op met onze klantenservice.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'DomainNameFree',
  components: {
    Breadcrumbs,
  },
}
</script>